import Supabase from "../Utils/supabase";

export const generateUsername = () => {
  const adjectives = [
    "Brave",
    "Clever",
    "Mysterious",
    "Bold",
    "Eager",
    "Quiet",
    "Fearless",
    "Witty",
    "Curious",
    "Daring",
    "Lively",
    "Noble",
    "Wise",
    "Adventurous",
    "Swift",
    "Fierce",
    "Gentle",
    "Kind",
    "Loyal",
    "Playful",
    "Sly",
    "Charming",
    "Cunning",
    "Mighty",
    "Epic",
  ];

  const nouns = [
    "Explorer",
    "Seeker",
    "Dreamer",
    "Visionary",
    "Warrior",
    "Nomad",
    "Wanderer",
    "Inventor",
    "Creator",
    "Thinker",
    "Guardian",
    "Pioneer",
    "Traveler",
    "Scholar",
    "Scribe",
    "Sorcerer",
    "Adventurer",
    "Vagabond",
    "Challenger",
    "Phantom",
    "Hunter",
    "Ranger",
    "Rogue",
    "Hero",
  ];

  // Generate a random number for extra uniqueness
  const randomNum = Math.floor(100 + Math.random() * 900); // Generates a number between 100 and 999

  // Combine a random adjective and noun with the random number
  const randomAdj = adjectives[Math.floor(Math.random() * adjectives.length)];
  const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];

  // Return the final username
  return `${randomAdj}${randomNoun}${randomNum}`;
};

export const fetchComments = async (companyId) => {
  const { data, error } = await Supabase.from("sponsorcomments")
    .select("*")
    .eq("company_id", companyId)
    .is("is_deleted", false)
    .order("created_at", { ascending: true });

  if (error) {
    console.error("Error fetching comments:", error);
    return [];
  }

  return data;
};

export const buildCommentTree = (comments) => {
  const commentMap = {};

  comments.forEach((comment) => {
    comment.replies = [];
    commentMap[comment.id] = comment;
  });

  const structuredComments = [];

  comments.forEach((comment) => {
    if (comment.parent_comment_id) {
      const parent = commentMap[comment.parent_comment_id];
      if (parent) {
        parent.replies.push(comment);
      }
    } else {
      structuredComments.push(comment);
    }
  });

  return structuredComments; // Return the fully structured comment tree
};

export const postComment = async (companyId, commentText) => {
  const { data, error } = await Supabase.from("sponsorcomments")
    .insert([
      {
        company_id: companyId,
        comment_text: commentText,
        username: generateUsername(),
        likes: 0,
        dislikes: 0,
      },
    ])
    .select();

  if (error) {
    console.error("Error posting comment:", error);
    return null;
  } else {
    return data;
  }
};

export const postReply = async (companyId, parentCommentId, replyText) => {
  const { data, error } = await Supabase.from("sponsorcomments")
    .insert([
      {
        company_id: companyId,
        parent_comment_id: parentCommentId,
        comment_text: replyText,
        username: generateUsername(),
        likes: 0,
        dislikes: 0,
      },
    ])
    .select();

  if (error) {
    console.error("Error posting reply:", error);
    return null;
  } else {
    return data;
  }
};

export const softDeleteComment = async (commentId) => {
  const { data, error } = await Supabase.from("sponsorcomments")
    .update({ is_deleted: true })
    .eq("id", commentId);

  if (error) {
    console.error("Error soft-deleting comment:", error);
  } else {
    console.log("Comment deleted:", data);
  }
};

// Update likes using the custom RPC function
export const castVote = async (userId, commentId, voteType) => {
  const { data, error } = await Supabase.from("votes").insert([
    { user_id: userId, comment_id: commentId, vote_type: voteType },
  ]);

  if (error) {
    console.error("Error casting vote:", error);
    return null;
  }

  // Get the updated count of votes for the comment
  const { count, error: countError } = await Supabase.from("votes")
    .select("*", { count: "exact" })
    .eq("comment_id", commentId);

  if (countError) {
    console.error("Error getting vote count:", countError);
    return null;
  }

  return { voteData: data, voteCount: count };
};
export const updateVote = async (userId, commentId, voteType) => {
  const { data, error } = await Supabase.from("votes")
    .update({ vote_type: voteType })
    .eq("user_id", userId)
    .eq("comment_id", commentId);

  if (error) {
    console.error("Error updating vote:", error);
    return null;
  }

  // Get the updated count of votes for the comment
  const { count, error: countError } = await Supabase.from("votes")
    .select("*", { count: "exact" })
    .eq("comment_id", commentId);

  if (countError) {
    console.error("Error getting vote count:", countError);
    return null;
  }

  return { updatedVoteData: data, voteCount: count };
};

export const checkIfUserHasVoted = async (userId, commentId) => {
  const { data, error } = await Supabase.from("votes")
    .select("*")
    .eq("user_id", userId)
    .eq("comment_id", commentId);

  if (error) {
    console.error("Error checking vote:", error);
    return null;
  }

  return data.length > 0 ? data[0] : null; // Return the existing vote or null
};
