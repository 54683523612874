import React, { useState } from "react";
import DynamicChart from "../../components/Charts/DynamicChart";
import DataFilter from "../../components/Charts/DataFilter";
import TitleSection from "./TitleSection";
import InfoSection from "./InfoSection";
import SidePanel from "../../components/Charts/sidePanel";

export const metricContexts = {
  PetitionsFiled: "Petitions Filed",
  ApprovedPetitions: "Approved Petitions",
  PetitionsApprovedByCountryOfBirthAndGender: "Petitions by Country & Gender",
  PetitionsApprovedByAgeAndGender: "Petitions by Age & Gender",
  PetitionsApprovedByEducationAndGender: "Petitions by Education & Gender",
  PetitionsApprovedByOccupation: "Petitions by Occupation",
  CompensationByOccupationsApproved: "Compensation by Occupation",
  CompensationOccupationAndGender: "Compensation by Occupation & Gender",
  CompensationByEducation: "Compensation by Education",
  IndustrySector: "Industry Sector",
  LocationAndPriorStatus: "Location & Prior Status",
  ValidityPeriod: "Validity Period",
  PremiumProcessing: "Premium Processing",
  RequestsForEvidence: "Requests for Evidence",
};

const Charts = () => {
  const [filters, setFilters] = useState({
    startYear: "2019",
    endYear: "2023",
    dataMetric: "PetitionsFiled",
    country: "India",
    ageRange: "35-39",
    educationLevel: "Master's Degree",
    occupation: "Computer-Related",
    location: "Students",
    validityPeriod: "Less than 12 months",
  });

  return (
    <div className="flex flex-wrap min-h-screen p-4">
      <TitleSection />
      {/* Container to align titles and content */}
      <div className="flex flex-wrap w-full items-start">
        {/* Filter and Chart Section */}
        <div className="w-full lg:w-2/3 p-4">
          <DataFilter filters={filters} setFilters={setFilters} />
          <div className="mt-4">
            <DynamicChart
              startYear={filters.startYear}
              endYear={filters.endYear}
              dataMetric={filters.dataMetric}
              country={filters.country}
              metricContexts={metricContexts}
              ageRange={filters.ageRange}
              education={filters.educationLevel}
              occupation={filters.occupation}
              location={filters.location}
              validityPeriod={filters.validityPeriod}
            />
          </div>
        </div>

        {/* Info Card Section */}
        <SidePanel selectedMetric={filters.dataMetric} filters={filters} />
      </div>

      {/* <InfoSection /> */}
    </div>
  );
};

export default Charts;
