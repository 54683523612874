import { toast } from "react-toastify";
import Supabase from "../Utils/supabase";
import Cookies from "js-cookie";
import { googleLogout } from "@react-oauth/google";

export const signUpUser = async (payload) => {
  try {
    const { data, error } = await Supabase.auth.signUp(payload);
    if (error?.status || error?.code) {
      return error;
    }

    return data;
  } catch (error) {
    return null;
  }
};

export const insertToUsers = async (payload) => {
  try {
    const { data, error } = await Supabase.from("users").insert([
      {
        email: payload?.email,
        first_name: payload?.firstName,
        last_name: payload?.lastName,
        profile_image: payload?.picture || null,
      },
    ]);
    if (error) {
      return null;
    }

    return data;
  } catch (error) {
    return null;
  }
};

export const fetchUserByEmail = async (email) => {
  let { data: user, error: userError } = await Supabase.from("users")
    .select("*")
    .eq("email", email)
    .single();

  if (userError && userError.code === "PGRST116") {
  } else if (userError) {
    throw userError;
  } else return user;
};

export const loginUser = async (payload) => {
  try {
    const { data, error } = await Supabase.auth.signInWithPassword(payload);

    if (error?.status === 400) {
      return error;
    }
    Cookies.set("token", data?.session?.access_token);
    Cookies.set("expiry", data?.session?.expires_at);
    Cookies.set("refresh_token", data?.session?.refresh_token);

    return data;
  } catch (error) {
    return null;
  }
};

export const handleGoogleLogin = async (response) => {
  const { data, error } = await Supabase.auth.signInWithIdToken({
    provider: "google",
    token: response.credential,
    nonce: "NONCE", // must be the same one as provided in data-nonce (if any)
  });
  if (error) {
    toast.error(
      "You have exceeded the number of login attempts allowed in a short period. Please wait a few minutes before trying again."
    );
  }
  if (data?.session) {
    Cookies.set("token", data?.session?.access_token);
    Cookies.set("expiry", data?.session?.expires_at);
    Cookies.set("refresh_token", data?.session?.refresh_token);
    return true;
  }
};

export const logoutUser = async () => {
  try {
    googleLogout();
    const { error } = await Supabase.auth.signOut();
    if (error) {
      return error;
    } else {
      Cookies.remove("token");
      Cookies.remove("expiry");
      Cookies.remove("refresh_token");
    }
  } catch (error) {}
};

export const getCurrentUser = async () => {
  const { data, error } = await Supabase.auth.getUser();
  if (error) return error;
  else return data;
};

export const getUserByEmail = async (email) => {
  const { data, error } = await Supabase.from("users") // Specify the table name
    .select("*") // Select all columns or specify certain columns
    .eq("email", email); // Filter by email

  if (error) {
    console.error("Error fetching user:", error.message);
    return null;
  }

  return data; // The result will be an array of users matching the email
};

export const saveJob = async (payload) => {
  try {
    const { data, error } = await Supabase.from("SavedJobs").insert(payload);
    if (error?.code) {
      return error;
    }

    return data;
  } catch (error) {
    return null;
  }
};

export const getSelectedJob = async (payload) => {
  try {
    const { data, error } = await Supabase.from("SavedJobs")
      .select("*")
      .eq("user_id", payload?.user_id)
      .eq("job_id", payload?.job_id);
    if (error?.code) {
      return error;
    }

    return data;
  } catch (error) {
    return null;
  }
};

export const getSavedJobsByUserId = async (user_id) => {
  try {
    const { data, error } = await Supabase.from("SavedJobs")
      .select("*")
      .eq("user_id", user_id);
    if (error?.code) {
      return error;
    }
    return data;
  } catch (error) {
    return null;
  }
};

export const deleteSavedJobsByUserId = async (job_id) => {
  try {
    const response = await Supabase.from("SavedJobs").delete().eq("id", job_id);

    return response;
  } catch (error) {
    return null;
  }
};

export const getAutoAppliedJobsByUserId = async (user_id, transaction_id) => {
  try {
    const { data, error } = await Supabase.from("AutoAppliedJobs")
      .select("*")
      .in("transaction_id", transaction_id)
      .eq("user_id", user_id);
    if (error?.code) {
      return error;
    }
    return data;
  } catch (error) {
    return null;
  }
};

export const getCurrentSession = async () => {
  const { data, error } = await Supabase.auth.getSession();
  if (error || data?.session == null) {
    Cookies.remove("token");
    Cookies.remove("expiry");
    Cookies.remove("refresh_token");

    return false;
  } else {
    Cookies.set("token", data?.session?.access_token);
    Cookies.set("expiry", data?.session?.expires_at);
    Cookies.set("refresh_token", data?.session?.refresh_token);
    return true;
  }
};
