import React, { useMemo, useRef } from "react";
import ReactECharts from "echarts-for-react";
import { getApprovedPetitions, getIndustrySectorData } from "../dataReader"; // Ensure this import path is correct

const IndustrySectorChart = ({ startYear, endYear, metricContexts }) => {
  const chartRef = useRef();

  const processedData = useMemo(
    () => getIndustrySectorData(parseInt(startYear, 10), parseInt(endYear, 10)),
    [startYear, endYear]
  );

  const chartOptions = useMemo(() => {
    const years = Object.keys(processedData);
    const values = Object.values(processedData);

    return {
      backgroundColor: "#f4f6f8",
      title: {
        text: `${metricContexts.IndustrySector} from ${startYear} to ${endYear}`,
        left: "center",
        textStyle: {
          color: "#333",
          fontWeight: "bold",
          fontSize: 16,
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line",
        },
      },
      grid: {
        left: "5%",
        right: "5%",
        bottom: "10%",
        top: "20%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: years,
        axisLabel: {
          rotate: 45,
          color: "#666",
        },
        axisLine: {
          lineStyle: {
            color: "#aaa",
          },
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          color: "#666",
        },
        splitLine: {
          lineStyle: {
            color: "#eee",
          },
        },
        axisLine: {
          lineStyle: {
            color: "#aaa",
          },
        },
      },
      series: [
        {
          name: "Total Sectors",
          type: "line",
          data: values,
          lineStyle: {
            width: 2,
            color: "#2ca02c",
          },
          itemStyle: {
            color: "#2ca02c",
          },
          areaStyle: {
            color: "rgba(44, 160, 44, 0.2)",
          },
        },
      ],
    };
  }, [processedData, startYear, endYear, metricContexts]);

  return (
    <div className="flex flex-col items-center">
      <div className="w-full lg:w-full h-[400px]">
        {Object.keys(processedData).length > 0 ? (
          <ReactECharts
            ref={chartRef}
            option={chartOptions}
            style={{ height: "100%", width: "100%" }}
            className="shadow-lg rounded-md border mb-4"
          />
        ) : (
          <div className="text-center mt-4 text-gray-600">
            No data available for the selected range.
          </div>
        )}
      </div>
    </div>
  );
};

export default IndustrySectorChart;
