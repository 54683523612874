import React, { useMemo, useRef } from "react";
import ReactECharts from "echarts-for-react";
import { getApprovedPetitionsByOccupation } from "../dataReader"; // Ensure this import path is correct

const ApprovedPetitionsByOccupationChart = ({
  startYear,
  endYear,
  metricContexts,
  occupation,
}) => {
  const chartRef = useRef();

  const processedData = useMemo(
    () =>
      getApprovedPetitionsByOccupation(
        parseInt(startYear, 10),
        parseInt(endYear, 10)
      ),
    [startYear, endYear]
  );
  const chartOptions = useMemo(() => {
    const years = Object.keys(processedData);

    if (!years.length) return null;

    // Create a multi-line chart for gender distribution by occupation
    const occupations =
      processedData[years[1]]?.map((obj) => Object.keys(obj))?.flat() || [];

    const series = occupations
      .map((occupation) => {
        return [
          {
            name: occupation,
            type: "line",
            data: years.map((year) =>
              year
                ? processedData[year]?.find(
                    (occ) => Object.keys(occ)[0] == occupation
                  )?.[occupation] || 0
                : 0
            ),
            smooth: true,
          },
        ];
      })
      .flat();

    return {
      backgroundColor: "#f4f6f8",
      title: {
        text: `${metricContexts.CompensationOccupationAndGender} from ${startYear} to ${endYear}`,
        left: "center",
        textStyle: {
          color: "#333",
          fontWeight: "bold",
          fontSize: 16,
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line",
        },
      },
      legend: {
        data: occupation ? [occupation] : occupations,
        top: "5%",
      },
      grid: {
        left: "5%",
        right: "5%",
        bottom: "10%",
        top: "20%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: years,
        axisLabel: {
          rotate: 45,
          color: "#666",
        },
        axisLine: {
          lineStyle: {
            color: "#aaa",
          },
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          color: "#666",
        },
        splitLine: {
          lineStyle: {
            color: "#eee",
          },
        },
        axisLine: {
          lineStyle: {
            color: "#aaa",
          },
        },
      },
      series: occupation
        ? series?.filter((data) => data?.name == occupation)
        : series,
    };
  }, [processedData, startYear, endYear, metricContexts, occupation]);

  return (
    <div className="flex flex-col items-center">
      <div className="w-full lg:w-full h-[400px]">
        {Object.keys(processedData).length > 0 ? (
          <ReactECharts
            ref={chartRef}
            option={chartOptions}
            style={{ height: "100%", width: "100%" }}
            className="shadow-lg rounded-md border mb-4"
          />
        ) : (
          <div className="text-center mt-4 text-gray-600">
            No data available for the selected range.
          </div>
        )}
      </div>
    </div>
  );
};

export default ApprovedPetitionsByOccupationChart;
