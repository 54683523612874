import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { jsonData } from "./data";

const DataFilter = ({ filters, setFilters }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };
  // Helper function to check if a metric has country data
  const hasCountryData = (dataMetric, year) => {
    return jsonData?.[dataMetric]?.[year]?.Country !== undefined;
  };

  // Helper function to check if a metric has age range data
  const hasAgeRangeData = (dataMetric, year) => {
    return jsonData?.[dataMetric]?.[year]?.Age !== undefined;
  };

  // Helper function to check if data has education level data
  const hasEducationLevel = (dataMetric, year) => {
    return jsonData?.[dataMetric]?.[year]?.EducationLevel !== undefined;
  };

  // Helper function to check if data has occupation data
  const hasOccupation = (dataMetric, year) => {
    return jsonData?.[dataMetric]?.[year]?.Occupation !== undefined;
  };

  const hasLocation = (dataMetric, year) => {
    return jsonData?.[dataMetric]?.[parseInt(year) + 1]?.Location !== undefined;
  };

  const hasValidityPeriod = (dataMetric, year) => {
    return (
      jsonData?.[dataMetric]?.[parseInt(year) + 1]?.ValidityPeriod !== undefined
    );
  };

  // Check if the selected data metric has country data
  const showCountryDropdown = hasCountryData(
    filters.dataMetric,
    filters.startYear
  );

  // Check if the selected data metric has age range data
  const showAgeRangeDropdown = hasAgeRangeData(
    filters.dataMetric,
    filters.startYear
  );

  // Check if the selected data metric has education level or occupation data
  const showEducationLevelDropdown = hasEducationLevel(
    filters.dataMetric,
    parseInt(filters.startYear) + 1
  );

  const showOccupationDropdown = hasOccupation(
    filters.dataMetric,
    filters.startYear
  );

  const showLocationDropdown = hasLocation(
    filters.dataMetric,
    filters.startYear
  );

  const showValidityPeriodDropdown = hasValidityPeriod(
    filters.dataMetric,
    filters.startYear
  );

  // Get the list of countries if applicable
  const countryOptions = showCountryDropdown
    ? Object.keys(
        jsonData?.[filters.dataMetric]?.[filters.startYear]?.Country || {}
      )
    : [];

  // Get the list of age ranges if applicable
  const ageRangeOptions = showAgeRangeDropdown
    ? Object.keys(
        jsonData?.[filters.dataMetric]?.[filters.startYear]?.Age || {}
      )
    : [];

  // Get the list of education levels if applicable
  const educationLevelOptions = showEducationLevelDropdown
    ? Object.keys(
        jsonData?.[filters.dataMetric]?.[parseInt(filters.startYear) + 1]
          ?.EducationLevel || {}
      )
    : [];

  // Get the list of occupations if applicable
  const occupationOptions =
    showOccupationDropdown ||
    filters?.dataMetric == "CompensationOccupationAndGender"
      ? Object.keys(
          jsonData?.[filters.dataMetric]?.[parseInt(filters?.startYear) + 1]
            ?.Occupation || {}
        )
      : filters?.dataMetric == "PetitionsApprovedByOccupation"
      ? Object.keys(
          jsonData?.[filters.dataMetric]?.[parseInt(filters?.startYear) + 1]
            ?.OccupationCategory || {}
        )
      : [];

  const locationOptions = showLocationDropdown
    ? Object.keys(
        jsonData?.[filters.dataMetric]?.[parseInt(filters.startYear) + 1]
          ?.Location || {}
      )
    : [];

  const validityPeriodOptions = showValidityPeriodDropdown
    ? Object.keys(
        jsonData?.[filters.dataMetric]?.[parseInt(filters.startYear) + 1]
          ?.ValidityPeriod || {}
      )
    : [];

  return (
    <div className="flex justify-center">
      <div className="w-full max-w-2xl">
        <Grid container spacing={4}>
          {/* Start Year */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControl size="small" fullWidth required>
              <InputLabel>Start Year</InputLabel>
              <Select
                name="startYear"
                value={filters.startYear}
                onChange={handleChange}
                label="Start Year"
              >
                {[2019, 2020, 2021, 2022, 2023].map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* End Year */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControl size="small" fullWidth>
              <InputLabel>End Year</InputLabel>
              <Select
                name="endYear"
                value={filters.endYear}
                onChange={handleChange}
                label="End Year"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {[2019, 2020, 2021, 2022, 2023].map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Data Metric */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControl size="small" fullWidth>
              <InputLabel>Data Metric</InputLabel>
              <Select
                name="dataMetric"
                value={filters.dataMetric}
                onChange={handleChange}
                label="Data Metric"
              >
                <MenuItem value="PetitionsFiled">Petitions Filed</MenuItem>
                <MenuItem value="ApprovedPetitions">
                  Approved Petitions
                </MenuItem>
                <MenuItem value="PetitionsApprovedByCountryOfBirthAndGender">
                  Petitions by Country & Gender
                </MenuItem>
                <MenuItem value="PetitionsApprovedByAgeAndGender">
                  Petitions by Age & Gender
                </MenuItem>
                <MenuItem value="PetitionsApprovedByEducationAndGender">
                  Petitions by Education & Gender
                </MenuItem>
                <MenuItem value="PetitionsApprovedByOccupation">
                  Petitions by Occupation
                </MenuItem>
                <MenuItem value="CompensationByOccupationsApproved">
                  Compensation by Occupation
                </MenuItem>
                <MenuItem value="CompensationOccupationAndGender">
                  Compensation by Occupation & Gender
                </MenuItem>
                <MenuItem value="CompensationByEducation">
                  Compensation by Education
                </MenuItem>
                {/* <MenuItem value="IndustrySector">Industry Sector</MenuItem> */}
                <MenuItem value="LocationAndPriorStatus">
                  Location & Prior Status
                </MenuItem>
                <MenuItem value="ValidityPeriod">Validity Period</MenuItem>
                <MenuItem value="PremiumProcessing">
                  Premium Processing
                </MenuItem>
                <MenuItem value="RequestsForEvidence">
                  Requests for Evidence
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Country Dropdown */}
          {showCountryDropdown && (
            <Grid item xs={12} sm={6} md={3}>
              <FormControl size="small" fullWidth>
                <InputLabel>Country</InputLabel>
                <Select
                  name="country"
                  value={filters.country || ""}
                  onChange={handleChange}
                  label="Country"
                >
                  {countryOptions.map((country) => (
                    <MenuItem key={country} value={country}>
                      {country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {/* Age Range Dropdown */}
          {showAgeRangeDropdown && (
            <Grid item xs={12} sm={6} md={3}>
              <FormControl size="small" fullWidth>
                <InputLabel>Age Range</InputLabel>
                <Select
                  name="ageRange"
                  value={filters.ageRange || ""}
                  onChange={handleChange}
                  label="Age Range"
                >
                  {ageRangeOptions.map((ageRange) => (
                    <MenuItem key={ageRange} value={ageRange}>
                      {ageRange}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {/* Education Level Dropdown */}
          {showEducationLevelDropdown && (
            <Grid item xs={12} sm={6} md={3}>
              <FormControl size="small" fullWidth>
                <InputLabel>Education Level</InputLabel>
                <Select
                  name="educationLevel"
                  value={filters.educationLevel || ""}
                  onChange={handleChange}
                  label="Education Level"
                >
                  {educationLevelOptions.map((level) => (
                    <MenuItem key={level} value={level}>
                      {level}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {/* Occupation Dropdown */}
          {(showOccupationDropdown ||
            filters?.dataMetric == "CompensationOccupationAndGender" ||
            filters?.dataMetric == "PetitionsApprovedByOccupation") && (
            <Grid item xs={12} sm={6} md={3}>
              <FormControl size="small" fullWidth>
                <InputLabel>Occupation</InputLabel>
                <Select
                  name="occupation"
                  value={filters.occupation || ""}
                  onChange={handleChange}
                  label="Occupation"
                >
                  {occupationOptions.map((occupation) => (
                    <MenuItem key={occupation} value={occupation}>
                      {occupation}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {showLocationDropdown && (
            <Grid item xs={12} sm={6} md={3}>
              <FormControl size="small" fullWidth>
                <InputLabel>Location</InputLabel>
                <Select
                  name="location"
                  value={filters.location || ""}
                  onChange={handleChange}
                  label="Location"
                >
                  {locationOptions.map((location) => (
                    <MenuItem key={location} value={location}>
                      {location}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {showValidityPeriodDropdown && (
            <Grid item xs={12} sm={6} md={3}>
              <FormControl size="small" fullWidth>
                <InputLabel>Validity Period</InputLabel>
                <Select
                  name="validityPeriod"
                  value={filters.validityPeriod || ""}
                  onChange={handleChange}
                  label="Validity Period"
                >
                  {validityPeriodOptions.map((period) => (
                    <MenuItem key={period} value={period}>
                      {period}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default DataFilter;
