import { toast } from "react-toastify";
import Supabase from "../Utils/supabase";

export const getJobsFromCart = async (userId) => {
  const { data, error } = await Supabase.from("cart")
    .select("*")
    .select("*")
    .eq("user_id", userId);
  if (error) {
    toast.error("Error fetching jobs");
  }
  return data;
};

export const addJobsForTrack = async (payload) => {
  const response = await Supabase.from("track_jobs").insert(payload);
  return response;
};
