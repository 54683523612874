import React, { useMemo, useRef, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import { getCompensationByEducation, getEducationData } from "../dataReader";

const CompensationByEducationChart = ({
  startYear,
  endYear,
  education,
  metricContexts,
}) => {
  const chartRef = useRef();

  const processedData = useMemo(
    () =>
      getCompensationByEducation(
        parseInt(startYear, 10),
        parseInt(endYear, 10)
      ),
    [startYear, endYear]
  );

  const chartOptions = useMemo(() => {
    const years = Object.keys(processedData);

    // Create a stacked chart for all education levels
    const educationLevels = Object.keys(
      processedData[years[1]]?.EducationLevel || {}
    )?.filter((level) => level == education);

    const series = educationLevels.map((level) => ({
      name: level,
      type: "bar",
      stack: "total",
      data: years.map(
        (year) => processedData[year]?.EducationLevel?.[level] || 0
      ),
      barWidth: "60%",
      itemStyle: {
        borderRadius: [10, 10, 0, 0],
        shadowColor: "rgba(0, 0, 0, 0.2)",
        shadowBlur: 10,
      },
    }));

    return {
      backgroundColor: "#f4f6f8",
      title: {
        text: `${metricContexts.CompensationByEducation} from ${startYear} to ${endYear}`,
        left: "center",
        textStyle: {
          color: "#333",
          fontWeight: "bold",
          fontSize: 16,
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        data: educationLevels,
        top: "5%",
      },
      grid: {
        left: "5%",
        right: "5%",
        bottom: "10%",
        top: "20%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: years,
        axisLabel: {
          rotate: 45,
          color: "#666",
        },
        axisLine: {
          lineStyle: {
            color: "#aaa",
          },
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          color: "#666",
        },
        splitLine: {
          lineStyle: {
            color: "#eee",
          },
        },
        axisLine: {
          lineStyle: {
            color: "#aaa",
          },
        },
      },
      series: series,
    };
  }, [processedData, startYear, endYear, metricContexts, education]);

  return (
    <div className="flex flex-col items-center">
      <div className="w-full lg:w-full h-[400px]">
        {chartOptions ? (
          <ReactECharts
            ref={chartRef}
            option={chartOptions}
            style={{ height: "100%", width: "100%" }}
            className="shadow-lg rounded-md border mb-4"
          />
        ) : (
          <div className="text-center mt-4 text-gray-600">
            No data available for the selected range.
          </div>
        )}
      </div>
    </div>
  );
};

export default CompensationByEducationChart;
