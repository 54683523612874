import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import JobList from "../pages/JobList";
import Profile from "../pages/Profile";
import PaidMemberProfile from "../pages/PaidMemberProfile";
import MembershipPlan from "../pages/MembershipPlan";
import LoadAutoApplyPage from "../components/JobApplications";
import AutoApply from "../components/JobApplications/handleApplicationForm";
import { Elements } from "@stripe/react-stripe-js";
import stripePromise from "../Utils/stripe";
import JobApplicationForm from "../components/JobApplications/JobApplicationForm";
import ReceiptPage from "../components/JobApplications/ReceiptPage";
import CompanyStatistic from "../components/CompanyStatistic";
import ContactForm from "../components/Contact/ContactForm";
import CartJobList from "../pages/JobList/CartJobList";
import FullServicePlan from "../components/JobApplications/components/AutoApplyLandingPage/FullServicePlan";
import CheckoutForm from "../pages/checkoutForm";
import BlogList from "../components/Blog/BlogList";
import BlogView from "../components/Blog/BlogView";
import ScrollToTop from "../services/ScrollToTop"; // Import ScrollToTop
import AutoApplyFeature from "../components/JobApplications/components/AutoApplyLandingPage/AutoApplyDetailedInformation";
import SubjectBlogs from "../components/Blog/SubjectBlogs";
import Charts from "../pages/ChartsPage";
import LegalPage from "../components/Legal/Legal";
import ReferralSignUp from "../components/Referral/referral";
const AppRoutes = ({ updateCartCount }) => {
  const options = {
    mode: "payment",
    amount: 1099,
    currency: "usd",
    appearance: {},
  };

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path="/home"
          element={<Home updateCartCount={updateCartCount} />}
        />
        <Route
          path="/job-list/:jobId?"
          element={<JobList updateCartCount={updateCartCount} />}
        />
        <Route path="/referral" element={<ReferralSignUp />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/paid-user" element={<PaidMemberProfile />} />
        <Route path="/membershipPlan" element={<MembershipPlan />} />
        <Route
          path="/complete-job-search-service"
          element={<LoadAutoApplyPage />}
        />
        <Route path="/job-application-form" element={<JobApplicationForm />} />
        <Route path="/receipt" element={<ReceiptPage />} />
        <Route path="/news" element={<BlogList />} />
        <Route path="/news/:id" element={<BlogView />} />
        <Route path="/full-service-plan" element={<FullServicePlan />} />
        <Route path="/auto-apply-faq" element={<AutoApplyFeature />} />
        <Route path="subject/:subject" element={<SubjectBlogs />} />
        <Route path="/h1b-data-visualizations" element={<Charts />} />
        <Route path="/legal" element={<LegalPage />} />
        <Route
          path="/autoApplyForm"
          element={
            <Elements stripe={stripePromise} options={options}>
              <AutoApply />
            </Elements>
          }
        />
        <Route
          path="/sponsor-information/:companyId"
          element={<CompanyStatistic />}
        />
        <Route path="/sponsor-information" element={<CompanyStatistic />} />
        {/* <Route
          path="/general-visa-statistics"
          element={<GeneralVisaStatistic />}
        /> */}
        <Route path="/contact" element={<ContactForm />} />
        <Route
          path="/cart"
          element={<CartJobList updateCartCount={updateCartCount} />}
        />
        <Route
          path="/checkout"
          element={
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm />
            </Elements>
          }
        />
      </Routes>
    </>
  );
};

export default AppRoutes;
