import React, { useMemo, useRef, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import {
  getCompensationByOccupation,
  getCompensationByOccupationAndGender,
} from "../dataReader";

const CompensationWithGenderChart = ({
  startYear,
  endYear,
  occupation,
  metricContexts,
}) => {
  const chartRef = useRef();

  const processedData = useMemo(
    () =>
      getCompensationByOccupationAndGender(
        parseInt(startYear, 10),
        parseInt(endYear, 10)
      ),
    [startYear, endYear]
  );

  const chartOptions = useMemo(() => {
    const years = Object.keys(processedData);

    if (!years.length) return null;

    const series =[
      {
        name: "Male",
        type: "line",
        data: years.map((year) =>
          year
            ? processedData[year][occupation]?.Male || 0
            : 0
        ),
        smooth: true,
      },
      {
        name: "Female",
        type: "line",
        data: years.map((year) =>
          year
            ? processedData[year][occupation]?.Female || 0
            : 0
        ),
        smooth: true,
      },
    ]

    return {
      backgroundColor: "#f4f6f8",
      title: {
        text: `${metricContexts.CompensationOccupationAndGender} from ${startYear} to ${endYear}`,
        left: "center",
        textStyle: {
          color: "#333",
          fontWeight: "bold",
          fontSize: 16,
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line",
        },
      },
      legend: {
        data: ["Male", "Female"],
        top: "5%",
      },
      grid: {
        left: "5%",
        right: "5%",
        bottom: "10%",
        top: "20%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: years,
        axisLabel: {
          rotate: 45,
          color: "#666",
        },
        axisLine: {
          lineStyle: {
            color: "#aaa",
          },
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          color: "#666",
        },
        splitLine: {
          lineStyle: {
            color: "#eee",
          },
        },
        axisLine: {
          lineStyle: {
            color: "#aaa",
          },
        },
      },
      series: series,
    };
  }, [processedData, startYear, endYear, metricContexts, occupation]);

  return (
    <div className="flex flex-col items-center">
      <div className="w-full lg:w-full h-[400px]">
        {chartOptions ? (
          <ReactECharts
            ref={chartRef}
            option={{ ...chartOptions }}
            style={{ height: "100%", width: "100%" }}
            className="shadow-lg rounded-md border mb-4"
            lazyUpdate={true}
          />
        ) : (
          <div className="text-center mt-4 text-gray-600">
            No data available for the selected range.
          </div>
        )}
      </div>
    </div>
  );
};

export default CompensationWithGenderChart;
