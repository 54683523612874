import React, { useState, useEffect } from "react";
import { Typography, IconButton, Box, Divider } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { metricContexts } from "../../pages/ChartsPage";
import { jsonData, eventData } from "../../components/Charts/data";

const SidePanel = ({ selectedMetric, filters }) => {
  const [expandedYear, setExpandedYear] = useState(null);
  const [dataByYear, setDataByYear] = useState({});

  const context =
    jsonData[selectedMetric]?.context || "No information available.";

  useEffect(() => {
    updateData();
  }, [filters]);

  const updateData = () => {
    const data = jsonData[selectedMetric]
      ? { ...jsonData[selectedMetric] }
      : {};
    delete data.context;
    const latestYear = Object.keys(data).sort((a, b) => b - a)[0];
    if (latestYear) {
      setExpandedYear(latestYear);
    }
    
    let updatedData;

    switch (filters?.dataMetric) {
      case "PetitionsApprovedByCountryOfBirthAndGender":
        updatedData = Object.keys(data).reduce((acc, year) => {
          const countryData = data[year]?.Country?.[filters?.country];
          if (countryData) {
            acc[year] = { Total: countryData.Total };
          }
          return acc;
        }, {});
        setDataByYear({ ...updatedData });
        break;
      case "PetitionsApprovedByAgeAndGender":
        updatedData = Object.keys(data).reduce((acc, year) => {
          const ageRangeData = data[year]?.Age?.[filters?.ageRange];
          if (ageRangeData) {
            acc[year] = { Total: ageRangeData.Total };
          }
          return acc;
        }, {});
        setDataByYear({ ...updatedData });
        break;
      case "PetitionsApprovedByEducationAndGender":
        updatedData = Object.keys(data).reduce((acc, year) => {
          const educationLevelData =
            data[year]?.EducationLevel?.[filters?.educationLevel];
          if (educationLevelData) {
            acc[year] = { Total: educationLevelData.Number };
          }
          return acc;
        }, {});
        setDataByYear({ ...updatedData });
        break;
      case "PetitionsApprovedByOccupation":
        updatedData = Object.keys(data).reduce((acc, year) => {
          const occupationData =
            data[year]?.OccupationCategory?.[filters?.occupation];
          if (occupationData) {
            acc[year] = { Total: occupationData };
          }
          return acc;
        }, {});
        setDataByYear({ ...updatedData });
        break;
      case "CompensationByOccupationsApproved":
        updatedData = Object.keys(data).reduce((acc, year) => {
          const occupationData = data[year]?.Occupation?.[filters?.occupation];
          if (occupationData) {
            acc[year] = { Total: occupationData };
          }
          return acc;
        }, {});
        setDataByYear({ ...updatedData });
        break;
      case "CompensationOccupationAndGender":
        // Removing first element because it's null
        updatedData = Object.keys(data).reduce((acc, year) => {
          const occupationData = data[year]?.Occupation?.[filters?.occupation];
          if (occupationData) {
            acc[year] = { Total: occupationData?.Male + occupationData?.Female };
          }
          return acc;
        }, {});
        setDataByYear({ ...updatedData });
        break;
      case "CompensationByEducation":
        updatedData = Object.keys(data).reduce((acc, year) => {
          const educationLevelData =
            data[year]?.EducationLevel?.[filters?.educationLevel];
          if (educationLevelData) {
            acc[year] = { Total: educationLevelData.MedianSalary };
          }
          return acc;
        }, {});
        setDataByYear({ ...updatedData });
        break;
      case "IndustrySector":
        updatedData = Object.keys(data).reduce((acc, year) => {
          const total = Object.values(data[year].Sector).reduce((sum, value) => sum + value, 0);
          acc[year] = { Total: total };
          return acc;
        }, {});
        setDataByYear({ ...updatedData });
        break;
      case "LocationAndPriorStatus":
        updatedData = Object.keys(data).reduce((acc, year) => {
          const locationData =
            data[year]?.Location?.[filters?.location];
          if (locationData) {
            acc[year] = { Total: locationData };
          }
          return acc;
        }, {});
        setDataByYear({ ...updatedData });
        break;
      case "ValidityPeriod":
        updatedData = Object.keys(data).reduce((acc, year) => {
          const locationData =
            data[year]?.ValidityPeriod?.[filters?.validityPeriod];
          if (locationData) {
            acc[year] = { Total: locationData };
          }
          return acc;
        }, {});
        setDataByYear({ ...updatedData });
        break;
      case "PremiumProcessing":
        updatedData = Object.keys(data).reduce((acc, year) => {
          const premiumRequestsCount =
            data[year]?.NotRequested + data[year]?.Requested;
          if (premiumRequestsCount) {
            acc[year] = { Total: premiumRequestsCount };
          }
          return acc;
        }, {});
        setDataByYear({ ...updatedData });
        break;
      case "RequestsForEvidence":
        updatedData = Object.keys(data).reduce((acc, year) => {
          const requests =
            data[year]?.ApprovedWithRFE + data[year]?.ApprovedWithoutRFE + data[year]?.DeniedWithRFE + data[year]?.DeniedWithoutRFE;
          if (requests) {
            acc[year] = { Total: requests };
          }
          return acc;
        }, {});
        setDataByYear({ ...updatedData });
        break;
      default:
        setDataByYear({ ...data });
    }
  };

  const toggleYearDetails = (year) => {
    setExpandedYear((prevYear) => (prevYear === year ? null : year));
  };

  const getSentimentStyle = (sentiment) => {
    switch (sentiment.toLowerCase()) {
      case "negative":
        return {
          textDecoration: "underline",
          textDecorationColor: "rgba(255, 99, 71, 0.5)",
          textDecorationThickness: "3px",
        }; // light red
      case "mixed":
      case "neutral":
        return {
          textDecoration: "underline",
          textDecorationColor: "rgba(255, 165, 0, 0.5)",
          textDecorationThickness: "3px",
        }; // light orange
      case "positive":
        return {
          textDecoration: "underline",
          textDecorationColor: "rgba(144, 238, 144, 0.5)",
          textDecorationThickness: "3px",
        }; // light green
      default:
        return {};
    }
  };

  return (
    <Box className="w-full lg:w-1/3 p-4">
      <Box className="bg-white rounded-lg shadow-md p-6">
        <Typography variant="h5" component="h2" gutterBottom>
          {metricContexts[selectedMetric]} Information
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          {context}
        </Typography>
        {Object.keys(dataByYear).sort((a, b) => b - a).length > 0 ? (
          <Box>
            {Object.entries(dataByYear)
              .sort((a, b) => b[0] - a[0])
              .map(([year, data]) => {
                return (
                  <Box key={year} mb={2}>
                    <Divider />
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={2}
                    >
                      <Typography variant="h6">{year}</Typography>
                      <IconButton
                        onClick={() => toggleYearDetails(year)}
                        size="small"
                      >
                        {expandedYear === year ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </IconButton>
                    </Box>
                    <Box ml={2} mt={1} color="textPrimary">
                      {data?.Total ? (
                        <Typography variant="body2">
                          Total: <strong>{data?.Total}</strong>
                        </Typography>
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          Data unavailable
                        </Typography>
                      )}
                      {data?.Breakdown && (
                        <ul>
                          {Object.entries(data?.Breakdown).map(
                            ([key, value]) => (
                              <li key={key}>
                                <Typography variant="body2">
                                  {key}: <strong>{value}</strong>
                                </Typography>
                              </li>
                            )
                          )}
                        </ul>
                      )}
                      {/* Collapsible section for events */}
                      {eventData[year] && eventData[year]?.events && (
                        <Box mt={2}>
                          {expandedYear === year && (
                            <Box>
                              <Typography
                                variant="h6"
                                gutterBottom
                                align="center"
                              >
                                Policy Changes
                              </Typography>
                              <Box color="textSecondary">
                                {Object.entries(eventData[year]?.events)?.map(
                                  ([eventKey, event]) => (
                                    <Box key={eventKey} mb={2}>
                                      <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                      >
                                        {event.summary}
                                      </Typography>
                                      <Typography variant="body2" paragraph>
                                        {event.detail}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        fontStyle="italic"
                                        color="textSecondary"
                                      >
                                        Effect on H-1B:{" "}
                                        <span
                                          style={getSentimentStyle(
                                            event.sentiment
                                          )}
                                        >
                                          {event.sentiment}
                                        </span>
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="primary"
                                        component="a"
                                        href={event.source}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Source
                                      </Typography>
                                    </Box>
                                  )
                                )}
                              </Box>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                );
              })}
          </Box>
        ) : (
          <Typography variant="body2" color="textSecondary">
            Yearly data not available.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default SidePanel;
