import { baseUrl, Token } from "../Utils/constants";
import CallApi from "../Utils/callApi";

//Checkout AutoApply
export const pingServer = (formData, resumeData, coverLetterPath, userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${baseUrl}/ping`;
      const apiOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${Token}`,
        },
        data: JSON.stringify({
          user: userId,
          email: formData?.email,
          // job_titles: formData?.jobTitles,
          job_titles: formData.preferred_job_titles,
          preferred_industries: formData.preferred_industries,
          preferred_job_titles: formData.preferred_job_titles,
          preferred_locations: formData.preffered_locations,
          preferred_job_types: formData.preferred_job_types,
          minimum_salary: formData.minimum_salary,
          additional_information: formData.additionalInformation,
          number_of_jobs: formData.number_of_jobs,
          resume: resumeData || "",
          cover_letter: coverLetterPath || "",
          transaction_id: formData.transaction_id,
        }),
      };

      const response = await CallApi(url, apiOptions);

      if (response.status !== 201) {
        // Handle error response
        const errorData = await response.json();
        reject(new Error(errorData.message || "Failed to ping server"));
      } else {
        // const responseData = await response.json();
        resolve(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};

//Checkout Job Cart
export const checkoutJobCart = (
  email,
  jobIds,
  resumeData,
  coverLetterPath,
  jobsWanted,
  transaction_id
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${baseUrl}/job-cart-checkout/`;
      const apiOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${Token}`,
        },
        data: JSON.stringify({
          email: email,
          job_ids: jobIds,
          resume_url: resumeData || "",
          cover_letter_url: coverLetterPath || "",
          jobs_wanted: jobsWanted,
          transaction_id,
        }),
      };

      const response = await CallApi(url, apiOptions);

      if (response.status !== 201) {
        reject(
          new Error(response.data.message || "Failed to checkout job cart")
        );
      } else {
        resolve(response.data);
      }
    } catch (error) {
      reject(error);
    }
  });
};
